import {memo} from 'react'
import styles from 'styled-components';

const Container = styles.div`
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 15px;
`;

export default memo(Container);
