import React, {FC, useState, useCallback} from 'react';
import {generatePath, Link, useLocation} from "react-router-dom";
import routes from '../../config/routes';
import moment from 'moment';

// Import Styles
import styled from 'styled-components';
import colors from '../../config/colors';
import Container from '../Layout/Container';
import H2 from '../Headings/H2';
import Row from '../Layout/Row';
import Expand from 'react-expand-animated';

import { FormattedMessage } from 'react-intl';
import generalMessages from '../../containers/generalMessages';

// Can I View
import Can from '../../config/acl/can';
import {viewSections} from '../../config/acl/aclReadSections';

import Col8 from '../Layout/Grid/Col8';
import Col4 from '../Layout/Grid/Col4';

import P from '../Paragraph';
import {  LinksSm } from '../LinksStyle';

const Footer: FC = () => {

  const location = useLocation();

  const [openForEmployers, setForEmployers] = useState(false);
  const [openForYou, setForYou] = useState(false);
  const [openGeneral, setGeneral] = useState(false);

  const toggleForEmployers = useCallback(() => {
    setForEmployers(!openForEmployers);
  }, [openForEmployers]);

  const toggleForYou = useCallback(() => {
    setForYou(!openForYou);
  }, [openForYou]);

  const toggleGeneral = useCallback(() => {
    setGeneral(!openGeneral);
  }, [openGeneral]);

  return (
    <FooterWrapper>
      <RowFooter>
        <Col8Footer>
          <FooterNav>
            <Row>
              <Col4List>
                <H2 className={openForEmployers ? 'listExpand' : ''} onClick={toggleForEmployers}><FormattedMessage {...generalMessages.ForEmployer} /><svg className="isvg"><use xlinkHref={`#arrow-down`} /></svg></H2>
                <ExpandFooter className={openForEmployers ? 'listExpand' : ''} open={openForEmployers} duration={300}>
                  <ul>
                    <li>
                      <Can I="view" a={viewSections.section.footer.regular}>
                        <Link
                          to={{
                            pathname: generatePath(routes.OurServices),
                            state: {
                              job: true
                            }
                          }}
                        >
                          <FormattedMessage {...generalMessages.SetJob} /><svg><use xlinkHref={`#arrow-right`} /></svg>
                        </Link>
                      </Can>
                      <Can I="view" a={viewSections.section.ourOffer.jobCTA}>
                        <Link to={generatePath(routes.PriceList)}><FormattedMessage {...generalMessages.SetJob} /><svg><use xlinkHref={`#arrow-right`} /></svg></Link>
                      </Can>
                    </li>
                    <Can I="view" a={viewSections.section.footer.regular}>
                      <li>
                        <Link
                          to={{
                            pathname: generatePath(routes.OurServices),
                            state: {
                              news: true
                            }
                          }}
                        >
                          <FormattedMessage {...generalMessages.SetNews} /><svg><use xlinkHref={`#arrow-right`} /></svg>
                        </Link>
                      </li>
                    </Can>
                    <Can I="view" a={viewSections.section.ourOffer.jobCTA}>
                      <li><Link to={generatePath(routes.NewsCreate)}><FormattedMessage {...generalMessages.SetNews} /><svg><use xlinkHref={`#arrow-right`} /></svg></Link></li>
                    </Can>
                    <li>
                    <Can I="view" a={viewSections.section.footer.regular}>
                      <Link
                        to={{
                          pathname: generatePath(routes.OurServices),
                          state: {
                            event: true
                          }
                        }}
                      >
                        <FormattedMessage {...generalMessages.SetEvent} /><svg><use xlinkHref={`#arrow-right`} /></svg>
                      </Link>
                    </Can>
                    <Can I="view" a={viewSections.section.ourOffer.jobCTA}>
                      <Link to={generatePath(routes.EventCreate)}><FormattedMessage {...generalMessages.SetEvent} /><svg><use xlinkHref={`#arrow-right`} /></svg></Link>
                    </Can>
                    </li>
                    <li>
                    <Can I="view" a={viewSections.section.footer.regular}>
                      <Link
                        to={{
                          pathname: generatePath(routes.OurServices),
                          state: {
                            preselection: true
                          }
                        }}
                      >
                        <FormattedMessage {...generalMessages.Preselection} /><svg><use xlinkHref={`#arrow-right`} /></svg>
                      </Link>
                    </Can>
                    <Can I="view" a={viewSections.section.ourOffer.jobCTA}>
                      <Link to={generatePath(routes.PreselectionJobList, {'type': 'preselection'})}><FormattedMessage {...generalMessages.Preselection} /><svg><use xlinkHref={`#arrow-right`} /></svg></Link>
                    </Can>
                    </li>
                    <li>
                    <Can I="view" a={viewSections.section.footer.regular}>
                      <Link
                        to={{
                          pathname: generatePath(routes.OurServices),
                          state: {
                            selection: true
                          }
                        }}
                      >
                        <FormattedMessage {...generalMessages.Selection} /><svg><use xlinkHref={`#arrow-right`} /></svg>
                      </Link>
                    </Can>
                    <Can I="view" a={viewSections.section.ourOffer.jobCTA}>
                      <Link to={generatePath(routes.PreselectionJobList, {'type': 'selection'})}><FormattedMessage {...generalMessages.Selection} /><svg><use xlinkHref={`#arrow-right`} /></svg></Link>
                    </Can>
                    </li>
                    <Can I="view" a={viewSections.section.footer.business}>
                      <li>
                        <Link className="purple-price"
                          to={{
                            pathname: generatePath(routes.PriceList),
                            state: {
                              basic: true
                            }
                          }}
                        >
                          <FormattedMessage {...generalMessages.PriceListBc} /><svg><use xlinkHref={`#arrow-right`} /></svg>
                        </Link>
                      </li>
                    </Can>
                  </ul>
                  <BusinessInfo>
                    <P>Više informacija o uslugama za poslodavce možete dobiti na <br/>
                    broj: <a href="tel:+38162330283">+381 62 420 729</a> <br/>
                    e-mail: <a href="mailto:hello@loop.rs">hello@loop.rs</a></P>
                    <P>Radno vreme: 09:00 - 17:00 h</P>
                  </BusinessInfo>
                </ExpandFooter>
              </Col4List>
              <Col4List>
                <H2 className={openForYou ? 'listExpand' : ''} onClick={toggleForYou}><FormattedMessage {...generalMessages.ForYou} /><svg className="isvg"><use xlinkHref={`#arrow-down`} /></svg></H2>
                <ExpandFooter className={openForYou ? 'listExpand' : ''} open={openForYou} duration={300}>
                  <ul>
                    <li>
                      <Link to={generatePath(routes.Jobs)}><FormattedMessage {...generalMessages.FindJob} /><svg><use xlinkHref={`#arrow-right`} /></svg></Link>
                    </li>
                    <li>
                      <Link to={generatePath(routes.NewsPage)}><FormattedMessage {...generalMessages.ReadNews} /><svg><use xlinkHref={`#arrow-right`} /></svg></Link>
                    </li>
                    <li>
                      <Link to={generatePath(routes.EventsPage)}><FormattedMessage {...generalMessages.readEvent} /><svg><use xlinkHref={`#arrow-right`} /></svg></Link>
                    </li>
                    <li>
                      <Link to={generatePath(routes.SupportAndDevelopment)}><FormattedMessage {...generalMessages.DevelopCarrer} /><svg><use xlinkHref={`#arrow-right`} /></svg></Link>
                    </li>
                  </ul>
                </ExpandFooter>
              </Col4List>
              <Col4List>
                <H2 className={openGeneral ? 'listExpand' : ''} onClick={toggleGeneral}><FormattedMessage {...generalMessages.InGeneral} /><svg className="isvg"><use xlinkHref={`#arrow-down`} /></svg></H2>
                <ExpandFooter className={openGeneral ? 'listExpand' : ''} open={openGeneral} duration={300}>
                  <ul>
                    <li>
                      <Link to={generatePath(routes.PrivacyPolicy)}><FormattedMessage {...generalMessages.PrivacyPolicy} /><svg><use xlinkHref={`#arrow-right`} /></svg></Link>
                    </li>
                    <li>
                      <Link to={generatePath(routes.TermsOfUse)}><FormattedMessage {...generalMessages.TermsOfUse} /><svg><use xlinkHref={`#arrow-right`} /></svg></Link>
                    </li>
                    {/* <li>
                      <Link to={generatePath(routes.Employers)}><FormattedMessage {...generalMessages.Employers} /><svg><use xlinkHref={`#arrow-right`} /></svg></Link>
                    </li> */}
                    <li>
                    <Link to={generatePath(routes.AboutUs)}><FormattedMessage {...generalMessages.AboutUs} /><svg><use xlinkHref={`#arrow-right`} /></svg></Link>
                    </li>
                  </ul>
                </ExpandFooter>
              </Col4List>
            </Row>
            <FooterInfo>
              <FooterLogo><svg><use xlinkHref={`#logo`} /></svg></FooterLogo>
              <FooterSocial>
                <FooterSocialItem><a href="https://www.facebook.com/9loop5" rel="noreferrer noopener" title='facebook' target="__blank"><svg className="isvg"><use xlinkHref={`#facebook`} /></svg></a></FooterSocialItem>
                <FooterSocialItem><a href="https://www.linkedin.com/company/9loop5/" rel="noreferrer noopener" title='linkedin' target="__blank"><svg className="isvg"><use xlinkHref={`#linkedin`} /></svg></a></FooterSocialItem>
                <FooterSocialItem><a href="https://instagram.com/loop.rs?igshid=YmMyMTA2M2Y=" rel="noreferrer noopener" title='9loop5' target="__blank"><svg className="isvg"><use xlinkHref={`#instagram`} /></svg></a></FooterSocialItem>
                <FooterSocialItem><a href="https://www.youtube.com/watch?v=NfGEL2szmtc&list=PLO7Nxil0DgcifYucIVh3YXThkLR2ojClu" title='youtube' rel="noreferrer noopener" target="__blank"><svg className="isvg"><use xlinkHref={`#youtube`} /></svg></a></FooterSocialItem>
              </FooterSocial>
            </FooterInfo>
          </FooterNav>
        </Col8Footer>
        <Col4Footer>
          <FooterContact>
            <H2><FormattedMessage {...generalMessages.ContactUs} /></H2>
            <P>Bilo da si u potrazi za novom poslovnom prilikom, ili karijernim razvojem, ili poslodavac koji traži novog člana za svoj tim, upitaj nas sve što te zanima!</P>
            <Info>
              <a href="mailto:office@loop.rs">office@loop.rs</a>
              <P>Pon-Pet 9-17h</P>
            </Info>
            <LinksSmContact theme="primary" to={{pathname: location.pathname , search: '?modal=true&ContactForm=true'}}>Kontaktiraj nas</LinksSmContact>
          </FooterContact>
        </Col4Footer>
      </RowFooter>
      <FooterBottom>
        <p>
          <FormattedMessage {...generalMessages.LoopCopyrightSign} /> {moment().format('YYYY')} <FormattedMessage {...generalMessages.LoopCopyright}
            values={{
              b: (...chunks: any) => <b>{chunks}</b>
            }}
          />
        </p>
      </FooterBottom>
    </FooterWrapper>
  )
}

const FooterWrapper = styled(Container)`
  position: relative;
  overflow: hidden;
  background-color: ${colors.GRAY_EXTRA_LIGHT};
  padding: 0;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
`;

const RowFooter = styled(Row)`
  margin: 0;
`;

const Col4Footer = styled(Col4)`
  padding: 0;
  margin-bottom: 0;
  @media (max-width: 992px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`

const Col8Footer = styled(Col8)`
  padding: 0;

  @media (max-width: 992px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;

const Col4List = styled(Col4)`

  @media (max-width: 768px) {
    margin: 0 0 6px !important;
  }
`;

const FooterNav = styled.div`
  position: relative;
  padding: 50px 80px 27px;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  line-height: 30px;

  .purple-price {
    color: ${colors.PRIMARY_PURPLE};
  }

  @media (max-width: 1200px) {
    padding: 50px 40px 27px;
  }

  @media (max-width: 768px) {
    padding: 28px 15px 18px;
    flex-direction: column-reverse;

    div {
      margin-bottom: 0;
    }
  }

  h2 {
    font-size: 20px;
    line-height: 30px;

    @media (max-width: 768px) {
      background: ${colors.WHITE};
      padding: 10px 15px;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .isvg {
      display: none;
      margin-left: 8px;
      fill: ${colors.PRIMARY_ORANGE};

      @media (max-width: 768px) {
        display: inline-block;
        vertical-align: middle;
      }
    }

    &.listExpand {

      .isvg {
        transition: transform .5s ease-in-out;
        transform: rotate(180deg);
      }

    }
  }

  li {
    color: ${colors.GRAY_MEDIUM};
    line-height: 30px;

    a {
      color: ${colors.BLACK};
      transition: color 200ms ease-in-out;

      svg {
        width: 7px;
        height: 11px;
        stroke: #FFA73A;
        margin-left: 10px;
        transition: margin 200ms ease-in-out;
      }

      &:hover {
        color: #FFA73A;

        svg {
          margin-left: 15px;
        }
      }
    }
  }
`;

const BusinessInfo = styled.div`
  padding: 10px 0;
  p {
    font-size: 14px;
  }
  @media (max-width: 768px) {
    padding: 10px 15px;
  }
`;

const FooterContact = styled.div`
  height: 100%;
  padding: 50px 80px 27px;
  background: linear-gradient(79.46deg, #634098 7.84%, #9671CE 102.08%);

  @media (max-width: 1200px) {
    padding: 50px 40px 27px;
  }

  @media (max-width: 768px) {
    padding: 26px 15px;
  }

  h2 {
    font-size: 44px;
    line-height: 54px;
    color: ${colors.WHITE_COLOR};

    @media (max-width: 576px) {
      font-size: 34px;
      line-height: 44px;
    }
  }
  p, a {
    color: ${colors.WHITE_COLOR};
  }
`;

const Info = styled.div`
  font-family: "Gilroy-Bold";
  margin: 14px 0;

  a {

    &:hover {
      color: #FFA73A;
    }
  }
`;

const LinksSmContact = styled(LinksSm)`
  width: 194px;
`;

const ExpandFooter = styled(Expand)`
  height: auto !important;
  opacity: 1 !important;

  @media (max-width: 768px) {
    background: ${colors.WHITE};

    height: 0 !important;
    opacity: 0 !important;

    &.listExpand {
      height: auto !important;
      opacity: 1 !important;
    }

    ul {
      margin: 0;
      padding: 10px 15px;
    }
  }
`;

const FooterSocial = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-left: auto;
  margin-right: 136px;

  @media (max-width: 768px) {
    margin-right: 0;
    justify-content: flex-end;
    margin-top: 0;
  }
`;

const FooterSocialItem = styled.span`
  width: 25px;
  height: 25px;
  margin-left: 12px;

  &:first-child {
    margin: 0;
  }

  .isvg {
    width: 25px;
    height: 25px;
    fill: ${colors.TEXT_GRAY};
    transition: fill 100ms ease-in-out;
  }

  a {
    display: block;
    transition: opacity 100ms ease-in-out;

    &:hover {

      svg {
        fill: ${colors.PURPLE_COLOR};
      }
    }
  }
`;

const FooterInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  order: 1;

  @media (max-width: 768px) {
    margin: 0 0 14px !important;
  }
`;

const FooterLogo = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: inline-block;
  }

  svg {
    width: 79px;
    height: 40px;
  }
`;

const FooterBottom = styled.div`
  background-color: ${colors.WHITE_COLOR};
  padding: 14px 0;
  text-align: right;

  @media (max-width: 576px) {
    padding: 8px 0;
  }

  p {
    color: ${colors.BLACK};
    margin: 0;
  }
`;

export default Footer;
