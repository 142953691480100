import styles from 'styled-components';

const Col8 = styles.div`
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
    position: relative;
    width: 100%;
    padding: 0 15px;

    @media (max-width: 768px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
`;

export default Col8;
